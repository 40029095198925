<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    v-model="saveDocumentDialog"
    transition="dialog-top-transition"
    width="700px"
    persistent
  >
    <v-card style="mt-3" class="pa-6">
      <v-card-title>
        <div class="d-flex justify-center font-weight-bold mb-2">
          <h4>{{ `Do you want to create ${documentType}?` }}</h4>
        </div>
        <div class="ml-auto">
          <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <ValidationObserver v-slot="{ invalid }" slim>
        <v-container>
          <div class="d-flex justify-center flex-column pa-2">
            <validation-provider
              v-if="documentType === 'template'"
              v-slot="{ errors }"
              slim
              rules="required"
            >
              <v-text-field
                v-model="templateNameValue"
                :error-messages="errors"
                rounded
                outlined
                placeholder="Please enter unique template name"
              />
            </validation-provider>
            <validation-provider
              v-else-if="documentType === 'program'"
              v-slot="{ errors }"
              slim
              rules="required"
            >
              <v-text-field
                v-model="programNameValue"
                :error-messages="errors"
                rounded
                outlined
                placeholder="Please enter unique program name"
              />
            </validation-provider>
            <v-btn
              color="green"
              class="white--text"
              x-large
              rounded
              depressed
              :dark="!invalid"
              :disabled="invalid"
              :loading="isSaveDocument"
              :ripple="false"
              @click="
                $emit('save-document', {
                  type: documentType,
                  nameValue: documentType === 'program' ? programNameValue : templateNameValue
                })
              "
              >Save</v-btn
            >
          </div>
        </v-container>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    saveDocumentDialog: {
      type: Boolean
    },
    documentType: {
      type: String,
      default: ''
    },
    isSaveDocument: {
      type: Boolean
    }
  },
  data() {
    return {
      programNameValue: '',
      templateNameValue: ''
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    saveDocument() {
      this.$emit('save-document', this.documentType);
    }
  }
};
</script>
