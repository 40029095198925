var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-top-transition","width":"700px","persistent":""},model:{value:(_vm.saveDocumentDialog),callback:function ($$v) {_vm.saveDocumentDialog=$$v},expression:"saveDocumentDialog"}},[_c('v-card',{staticClass:"pa-6",staticStyle:{}},[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-center font-weight-bold mb-2"},[_c('h4',[_vm._v(_vm._s(("Do you want to create " + _vm.documentType + "?")))])]),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-divider'),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('div',{staticClass:"d-flex justify-center flex-column pa-2"},[(_vm.documentType === 'template')?_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"rounded":"","outlined":"","placeholder":"Please enter unique template name"},model:{value:(_vm.templateNameValue),callback:function ($$v) {_vm.templateNameValue=$$v},expression:"templateNameValue"}})]}}],null,true)}):(_vm.documentType === 'program')?_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"rounded":"","outlined":"","placeholder":"Please enter unique program name"},model:{value:(_vm.programNameValue),callback:function ($$v) {_vm.programNameValue=$$v},expression:"programNameValue"}})]}}],null,true)}):_vm._e(),_c('v-btn',{staticClass:"white--text",attrs:{"color":"green","x-large":"","rounded":"","depressed":"","dark":!invalid,"disabled":invalid,"loading":_vm.isSaveDocument,"ripple":false},on:{"click":function($event){return _vm.$emit('save-document', {
                type: _vm.documentType,
                nameValue: _vm.documentType === 'program' ? _vm.programNameValue : _vm.templateNameValue
              })}}},[_vm._v("Save")])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }